import React from 'react'
import aboutPic from '../assets/Agile_1.jpg'

const AboutUs = () => {

      
    return (
        <section>
            <div className='SECTION2 flex text-white bg-black'>
                <div className='hidden md:flex w-1/2  flex-col items-center justify-center'><img src={aboutPic} alt="Restaurant Salesman" /></div>
                <div className='flex flex-col w-full md:w-1/2 items-center justify-center'>
                    <div className='w-2/3 flex flex-col items-start text-sm md:text-lg  mb-4 md:mb-12'>
                        <h1 className='text-xl md:text-4xl font-bold text-center mb-6'>About Us</h1>
                        <p className='pb-2'>Meet the Gulf Coast POS Provider with a Fresh Approach to Restaurant Point of Sale</p>
                        <p className='pb-2'>You need a restaurant point of sale (POS) system specifically designed for the restaurant industry, and you need a POS provider to make sure it equips your business with exactly the right tools you need to operate efficiently. Acadian Business Systems, paired with SpotOn, delivers.</p>
                        <p className='pb-2'>We are a Lafayette, LA-based restaurant POS dealer, providing POS systems to restaurants throughout Louisiana and the Gulf Coast. Our systems help them operate more profitably and create the dining experiences today’s consumers want. We partner with SpotOn to offer industry-leading cloud-based and hybrid restaurant POS software and solutions, designed to help you overcome your most pressing business challenges, live up to customer expectations, and give you a competitive edge.</p>
                    </div>
                </div>
            </div>
            <div className='flex flex-col md:flex-row items-center justify-center w-full py-12'>
                <div className='flex flex-col w-3/4 md:w-1/3 md:px-6'>
                    <h2 className='text-black text-2xl text-center md:text-left py-4 font-bold'>Run & Grow Your Business with SpotOn<span className='text-primary text-3xl'>.</span></h2>
                    <p className='text-secondaryDarker text-md text-center md:text-left'>Online ordering, digital reservations and waitlisting, and elite reporting to keep your guests and employees happy—it’s all possible with SpotOn.</p>
                </div>
                <div className='flex items-center justify-center w-full md:w-1/3 md:border-2 md:border-secondary rounded-2xl py-4 md:px-4'>
                <iframe  className='youtube rounded-2xl' src="https://www.youtube.com/embed/4YF9aMXXIVQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
        </section>
    )
}

export default AboutUs
