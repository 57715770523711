import React from 'react'
import a920 from '../assets/rest-a3.png'
import dots from '../assets/dotSquare.svg'
import shadow from '../assets/shadow.png'
import Typewriter from 'typewriter-effect'

const Hero = () => {
    return (
        <div className='hero' >
           <div className='absolute hidden md:block -rotate-12 w-1/3 -ml-36 mt-36' >
               <img src={dots} alt="Square with Dots" />
           </div>
            <div className='LOCAL text-center mb-10 md:mb-0 md:text-left font-bold text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-white'>
                <h3 className='text-xl text-secondaryLighter ml-0 md:-ml-6'>If you need a:</h3>
                <div className='typer'>
                    <Typewriter className="w-3/4" options={{strings:['No Contract','Customizeable','Flexible','Locally Supported'], autoStart: true, loop: true, cursorClassName: 'typerCursor'}} />
                </div>
                <br/>
                <div className='pt-2 md:pt-0  ml-0  md:ml-12 text-primaryLighter'>Point of Sale</div>
                <h3 className='text-lg lg:text-xl xl:text-2xl text-white ml-0 md:ml-24 mt-2 lg:ml-52 lg:mt-2'>WE HAVE WHAT YOU NEED</h3>
            </div>
            <div className='blue-bar w-full bg-primary'></div>
            <img src={a920} className='A920 animate-rotatebnf md:px-0  mt-24 md:mt-0' alt='A920' />
            <img src={shadow} alt="Shadow" className='SHADOW animate-shadowSize ' />
            {/*<button className='CONTACT px-8 py-4 bg-primaryDarker text-white rounded-3xl hover:bg-primaryLighter '>CONTACT US</button>*/}
           
        </div>
    )
}

export default Hero
