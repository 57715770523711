import tacoSisters from '../images/taco_sisters.jpg'
import nirvana from '../images/nirvana.jpg'
import pablo2 from '../images/pablo2.jpg'
import kazoku from '../images/kazoku.jpg'
import ladining from '../images/ladining.jpg'
import namwan from '../images/namwan.jpg'

const restaurantList = [
    {
        id: 1,
        name: "Taco Sisters",
        street: "3902 Johnson Street",
        city: "Lafayette, LA",
        zip: '70503',
        website: "https://order.spoton.com/acad-taco-sisters-9246/lafayette-la/628baa1c24b4c432c660f4eb",
        image: tacoSisters,
        type: 'Mexican',
        doordash: true,
        ddcost: 4,
        minOrder: 50,
        style: "fastFood"
    },
    {
        id: 2,
        name: "Nirvana Indian Cuisine",
        street: "4308 Magazine Street",
        city: "New Orleans, LA",
        zip: "70115",
        website: "https://order.spoton.com/acad-nirvana-indian-cuisine-9724/new-orleans-la/62b1e3b39adef3583bbdd539",
        image: nirvana,
        type: "Indian",
        doordash: false,
        ddcost: 4,
        minOrder: 50,
        style: "dineIn"
    },
    {
        id: 3,
        name: "Senor Pablo #2",
        street: "101 E 2nd Street Ste GR-265",
        city: "Tulsa, OK",
        zip: "74013",
        website: "https://order.spoton.com/acad-senor-pablo-2-9592/tulsa-ok/62a39cf224b4c407eff195f2",
        image: pablo2,
        type: "Mexican",
        doordash: false,
        ddcost: 0,
        minOrder: 50,
        style: "fastFood"
    },
    {
        id: 4,
        name: "Kazoku Sushi",
        street: "1680 Highway 59 Ste 800",
        city: "Mandeville, LA",
        zip: "70448",
        website: "https://order.spoton.com/acad-kazoku-sushi-8313/mandeville-la/6241ece09adef34d400f26db",
        image: kazoku,
        type: "Asian",
        doordash: false,
        ddcost: 0,
        minOrder: 50,
        style: "dineIn"
    },
    {
        id: 5,
        name: "Louisianna House Dining Hall",
        street: "900 N 3rd St",
        city: "Baton Rouge, LA",
        zip: "70802",
        website: "https://order.spoton.com/acad-one-more-time-7717/baton-rouge-la/620b224024b4c476957b7388",
        image: ladining,
        type: "American",
        doordash: false,
        ddcost: 0,
        minOrder: 50,
        style: "fastFood"
    },
    {
        id: 6,
        name: "Namwan Thai Kitchen",
        street: "3125 E Milton Ave Ste 12",
        city: "Youngsville, LA",
        zip: "70592",
        website: "https://order.spoton.com/6421daf4c10a7c003e57a3e7",
        image: namwan,
        type: "Asian",
        doordash: true,
        ddcost: 5,
        minOrder: 35,
        style: "dineIn"
    },

];

export default restaurantList