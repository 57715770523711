import React, { useState, useEffect } from 'react'
import emailjs from '@emailjs/browser'

const Contact = () => {
    const [values, setValues] = useState({
        name: "",
        email: "",
        company: "",
        phone: "",
        message: ""
    })

    const [status, setStatus] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.send('service_992hvwe', 'template_rtfoqb4', values, 'user_wwfuyICbDmDxSeuwypntm')
          .then(response => {
            console.log('SUCCESS!', response);
            setValues({
              name: '',
              company: '',
              email: '',
              phone: '',
              message: '',
              
            });
            setStatus('SUCCESS');
          }, error => {
            console.log('FAILED...', error);
          });
      }
      const handleChange = (e) => {
        setValues(values => ({
          ...values,
          [e.target.name]: e.target.value
        }))
      }
      useEffect(() => {
        if(status === 'SUCCESS') {
          setTimeout(() => {
            setStatus('');
          }, 3000);
        }
      }, [status]);
    return (
        <div id="contact" className='flex w-full items-center justify-center my-16'>
            <div className='w-5/6 h-screen md:pt-0 pt-4 flex md:flex-row flex-col items-center justify-center bg-contactBG bg-cover rounded-2xl'>
                <iframe className='md:w-1/2 w-3/4 h-3/4 mx-8' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6895.996718700451!2d-92.0156030238076!3d30.208594274841257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86249cec73129af3%3A0x2866afa607409ddd!2s719%20Coolidge%20St%2C%20Lafayette%2C%20LA%2070503!5e0!3m2!1sen!2sus!4v1713811199008!5m2!1sen!2sus" title='Map' allowFullScreen="" loading="lazy"></iframe>
                

                
                <form className='md:w-1/3 w-5/6 md:mt-0 mt-6 flex flex-col items-center justify-center' onSubmit={handleSubmit}>
                    {status && renderAlert()}
                    <h2>Ready to learn more?</h2>
                    <h1 className='text-center text-2xl text-primaryDarker font-bold my-2'>Contact Us</h1>
                    <input type="text" value={values.name} onChange={handleChange} name="name" className='md:w-3/4 w-full border-2 border-secondary rounded-xl px-6 py-2 my-2' placeholder='Name' required/>
                    <input type="text" value={values.company} onChange={handleChange} name="company" className='md:w-3/4 w-full border-2 border-secondary rounded-xl px-6 py-2 my-2' placeholder='Company' required/>
                    <input type="email" value={values.email} onChange={handleChange} name="email" className='md:w-3/4 w-full border-2 border-secondary rounded-xl px-6 py-2 my-2' placeholder='Email' required/>
                    <input type="text" value={values.phone} onChange={handleChange} name="phone" className='md:w-3/4 w-full border-2 border-secondary rounded-xl px-6 py-2 my-2' placeholder='Phone' required/>
                    
                    <textarea name="message" rows="8" value={values.message} onChange={handleChange} className='md:w-3/4 w-full border-2 border-secondary rounded-xl px-6 py-2 my-2' placeholder='Type Your Message Here' />
                    <input type="submit" className='bg-primary text-white text-xl px-6 py-2 rounded-2xl mt-4 cursor-pointer disabled:text-gray-300 disabled:bg-gray-400 disabled:hover:ring-gray-600' disabled={values.name.length > 3 && values.email.length > 6 ? false : true}/>
                    <p className='text-primaryDarker text-xs italic mt-4 md:mb-0 mb-4'>Need Support? Call us at (844) 246-3843 <br />or email <span className="pl-1 font-bold">support@acadianpos.com</span></p>
                    
                </form>
                
            </div>
        </div>
    )
}

const renderAlert = () => (
    <div className="absolute mt-[-37%] px-4 py-3 leading-normal text-green-700 bg-green-100 rounded mb-5 text-center">
      <p>Your message submitted successfully!</p>
    </div>
  )
export default Contact
