import React from 'react'
import fineDining from '../assets/fine-dining.png'
import fullService from '../assets/full-service.png'
import quickService from '../assets/qsr.png'
import restaurants from './restaurants'

const Serve = () => {
    const whoWeServe = [
        {
            icon: fineDining,
            type: 'Fine Dining',
            info: 'Offer your guests a seamless and refined dining experience with a custom end-to-end point of sale solution that is tailor-made to streamline operations.'
        },
        {
            icon: quickService,
            type: 'Quick Service',
            info: 'Keep the line moving fast and stand out from your competitors with integrated digital marketing and loyalty at checkout.'
        },
        {
            icon: fullService,
            type: 'Casual Full-Service',
            info: 'Let your customers order and pay their way with a flexible configuration that makes sense for your restaurant'
        }
    ]

    const serviceItems = whoWeServe.map((item) => (
            <div key={item.icon} className='whoWeServe w-auto md:w-1/3 flex flex-col px-4 mx-4 md:px-8 md:mx-3 my-2 md:my-0 items-center '>
                <img src={item.icon} alt="Restaurant Icon" className='w-1/5 pt-6 pb-4'/> 
                <h3 className='text-xl text-primaryDarker font-bold '>{item.type}</h3>
                <p className='text-primaryDarkest text-sm pt-4 pb-6 text-center md:text-left'>{item.info}</p>
            </div>
    ))
    return (
        <div className='flex flex-col items-center justify-center w-full mx-auto'>
        <h1 className='text-black font-bold text-3xl py-4 md:py-12 mx-auto'>Who We Serve<span className='text-primary text-3xl pl-2'>:</span></h1>
        <div className='w-full md:w-2/3 flex flex-col md:flex-row items-centered justify-between mx-auto'>
            {serviceItems}
        </div>
        <h1 className='text-black md:text-2xl text-xl font-bold pt-4 md:pt-12 mx-auto text-center '>Here are some of our outstanding Partners</h1>
        <div className='flex md:flex-row flex-col gap-12 md:w-3/4 items-center justify-center md:my-12 my-6'>
        {restaurants.map((restaurant) => {
            return (
                <img className='md:w-[10%] w-1/2 h-auto' src={restaurant.image} alt={restaurant.name} />
            )
        })}
        </div>
        </div>
    )
}

export default Serve
