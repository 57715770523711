
const data = [
    {
        "id": 1,
        "image": require("../assets/bevager.png"),
        "name": "Bevager by Craftable",
        "type": "Inventory"
    },
    {
        "id": 2,
        "image": require("../assets/chowly.jpg"),
        "name": "Chowly",
        "type": "Online Ordering"
    },
    {
        "id": 3,
        "image": require("../assets/gratshare.png"),
        "name": "GratShare",
        "type": "Gratuities"
    },
    {
        "id": 4,
        "image": require("../assets/gusto.png"),
        "name": "Gusto",
        "type": "Payroll"
    },
    {
        "id": 5,
        "image": require("../assets/doordash-drive.png"),
        "name": "DoorDash Drive",
        "type": "Online Ordering"
    },
    {
        "id": 6,
        "image": require("../assets/qsr-online.png"),
        "name": "QSR Online",
        "type": "Management"
    },
    {
        "id": 7,
        "image": require("../assets/compeat.png"),
        "name": "Compeat",
        "type": "Management"
    },
    {
        "id": 8,
        "image": require("../assets/ctuit.png"),
        "name": "CTUIT",
        "type": "Managment"
    },
    {
        "id": 9,
        "image": require("../assets/adp.png"),
        "name": "ADP",
        "type": "HR"
    },
    {
        "id": 10,
        "image": require("../assets/freshkds.png"),
        "name": "Fresh KDS",
        "type": "Kitchen Video"
    },
    {
        "id": 11,
        "image": require("../assets/cheftab.png"),
        "name": "ChefTab",
        "type": "Kitchen Video"
    },
    {
        "id": 12,
        "image": require("../assets/hot-schedules.png"),
        "name": "Hot Schedules",
        "type": "Scheduling"
    },
]

export default data