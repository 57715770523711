import React from 'react'
import whiteLogo from '../assets/AcadianLogoWhite.svg'
import { BsFacebook, BsTwitter  } from 'react-icons/bs'
import { AiFillInstagram, AiOutlineCopyright } from 'react-icons/ai'
import liftoff from '../assets/liftoff.png'

const Footer = () => {
    return (
        <footer className='w-full bg-primary flex flex-col items-center justify-center text-white'>
            <div className='w-full text-white h-72 md:h-60  pt-4 flex md:flex-row flex-col justify-center items-center md:items-start md:justify-between'>
            <div className='w-full px-6 md:px-0 md:w-1/2 my-auto md:ml-24'>
                <img src={whiteLogo} alt="Gulf Coast POS" className='w-full md:w-1/3' />
            </div>
            <div className='flex flex-col  my-auto justify-center items-center md:justify-end md:items-end md:mr-24 '>
                <div className='flex md:justify-end text-lg md:text-3xl text-white pb-4 '>
                    <span className=''><BsFacebook /></span>
                    <span className='pl-4'><BsTwitter /></span>
                    <span className='pl-4'><AiFillInstagram /></span>
                </div>
                <h2 className='text-md md:text-lg md:text-right'>719 Coolidge St</h2>
                <h2 className='text-md md:text-lg md:text-right'>Lafayette, LA 70503</h2>
                <h2 className='text-md md:text-lg md:text-right'>(844) 246-3843</h2>
                <h2 className='text-md md:text-md md:text-right'>info@acadianpos.com</h2>
                <div className='flex text-xs justify-end items-center text-right pt-4'>
                    <span className='pr-1'><AiOutlineCopyright/></span>
                    <h6>Copyright Acadian Business Systems 2022</h6>
                </div>                
            </div>
            </div>
            <div className='w-full flex justify-center items-center text-xs mb-4 italic'>site designed by<img src={liftoff} alt="Liftoff Creative" className='liftoff pl-2'/></div>
        </footer>
    )
}

export default Footer