import React, { useState } from 'react'
import {RiCloseCircleFill} from 'react-icons/ri'
// import { send } from 'emailjs-com'


const DemoModal = ({closeForm}) => {
    const [respMessage, setRespMessage] = useState('')

    const [toSend, setToSend] = useState({
        name: '',
        company: '',
        email: '',
        phone: ''
    })
    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value })
      }
    const onSubmit = (e) => {
        e.preventDefault()
        // send(
        //     'service_bp342hz',
        //     'template_8om1o1q',
        //     toSend,
        //     'user_wwfuyICbDmDxSeuwypntm'
        //   )
        //     .then((response) => {
        //       console.log('SUCCESS!', response.status, response.text)
        //       setRespMessage(response.text)
        //     })
        //     .catch((err) => {
        //       console.log('FAILED...', err)
        //     })

    }


    return (
        <div className='fixed w-screen h-screen flex justify-center items-center bg-modal-overlay bg-blend-overlay z-50'>
            <div className='bg-white w-1/3 h-auto drop-shadow-lg rounded-xl flex flex-col justify-center items-center'>
                <div className='my-4 mx-4 text-primaryDarker text-2xl self-end cursor-pointer' onClick={() => closeForm(false)}><RiCloseCircleFill /></div>
                <form className='w-full py-8 flex flex-col justify-center items-center' onSubmit={onSubmit}>
                   <h1 className='font-bold text-2xl text-primaryDarker text-center'>Schedule A Demo</h1> 
                   
                   <label  className='hidden text-left w-3/4'>Full Name</label>
                   <input type="text" id="from_name" name='name' value={toSend.name} placeholder='Full Name' className='w-3/4 px-3 py-4 border-2 rounded-xl mt-6 mb-2 placeholder:text-red' onChange={handleChange} required />
                   
                   <label  className='hidden text-left w-3/4'>Company Name</label>
                   <input type="text" id="cname" name='company' placeholder='Company Name' value={toSend.company} className='w-3/4 px-3 py-4 border-2 rounded-xl my-2'onChange={handleChange} />
                   
                   <label  className='hidden text-left w-3/4'>Email Address</label>
                   <input type="email" id="email" name='email' placeholder='Email Address' value={toSend.email} className='w-3/4 px-3 py-4 border-2 rounded-xl my-2' onChange={handleChange} required />
                   
                   <label  className='hidden text-left w-3/4'>Phone Number</label>
                   <input type="text" id="phone" name='phone' placeholder='Phone Number' value={toSend.phone} className='w-3/4 px-3 py-4 border-2 rounded-xl my-2'onChange={handleChange} required />
                   
                   <input type="submit" id="submit" value="Submit Request" className='my-5 text-white rounded-xl px-6 py-2 bg-primary' ></input>
                   <p className='text-sm text-center text-green-text'>{respMessage==="" ? respMessage : "Form Submission Successful! Please close this window"}</p>
                </form>
            </div>
        </div>
    )
}

export default DemoModal
