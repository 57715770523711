import React, {useState} from 'react'
import { AiFillHome } from 'react-icons/ai'
import { FaUserAlt, FaClipboardList, FaMailBulk} from "react-icons/fa"


const BottomNav = () => {
    const [ homeClick, setHomeClick] = useState(false)
    const [ aboutClick, setAboutClick] = useState(false)
    const [ featuresClick, setFeaturesClick] = useState(false)
    const [ contactClick, setContactClick] = useState(false)


    const handleHomeClick = (e) => {
        return ( 
            setHomeClick(true), 
            setAboutClick(false),
            setFeaturesClick(false),
            setContactClick(false)

        )
    }
    const handleAboutClick = (e) => {
        return ( 
            setHomeClick(false), 
            setAboutClick(true),
            setFeaturesClick(false),
            setContactClick(false)

        )
    }
    const handleFeaturesClick = (e) => {
        return ( 
            setHomeClick(false), 
            setAboutClick(false),
            setFeaturesClick(true),
            setContactClick(false)

        )
    }
    const handleContactClick = (e) => {
        return ( 
            setHomeClick(false), 
            setAboutClick(false),
            setFeaturesClick(false),
            setContactClick(true)

        )
    }
    
    return (
        <div className='flex items-center justify-center'>
            <div className='botNav bg-white fixed bottom-2 flex items-center justify-center rounded-2xl shadow-lg border-2 border-secondaryLightest'>
                <nav className='flex w-full items-center justify-center'>
                    <a className='flex flex-col w-1/5 items-center justify-center' href="#home" onClick={handleHomeClick}> 
                        <AiFillHome  className={homeClick ? 'text-primaryLighter text-lg ' : 'text-primaryDarker text-md '}/>
                        <h1 className={homeClick ? 'text-primaryLighter text-md font-bold' : 'text-primaryDarker text-sm'}>Home</h1>
                    </a>
                    <a className='flex flex-col w-1/5 items-center justify-center' href="#about" onClick={handleAboutClick}> 
                        <FaUserAlt className={aboutClick ? 'text-primaryLighter text-lg ' : 'text-primaryDarker text-md '}/>
                        <h1 className={aboutClick ? 'text-primaryLighter text-md font-bold' : 'text-primaryDarker text-sm'}>About</h1>
                    </a>
                    <a className='flex flex-col w-1/5 items-center justify-center' href='#features' onClick={handleFeaturesClick}> 
                        <FaClipboardList className={featuresClick ? 'text-primaryLighter text-lg ' : 'text-primaryDarker text-md '}/>
                        <h1 className={featuresClick ? 'text-primaryLighter text-md font-bold' : 'text-primaryDarker text-sm'}>Features</h1>
                    </a>
                    <a className='flex flex-col w-1/5 items-center justify-center' href="#contact" onClick={handleContactClick}> 
                        <FaMailBulk className={contactClick ? 'text-primaryLighter text-lg ' : 'text-primaryDarker text-md '}/>
                        <h1 className={contactClick ? 'text-primaryLighter text-md font-bold' : 'text-primaryDarker text-sm'}>Contact</h1>
                    </a>
                </nav>
            </div>
        </div>
    )
}

export default BottomNav
