import React from 'react'
import data from './integrationPartners'
import gusto from '../assets/gustoFeature.png'
const Integrations = () => {
    const newData = data.map((item) => {
       return (
            <div className='flex flex-col justify-start items-center w-3/4 md:w-1/6 px-6' key={item.id}>
                <div className='flex items-center justify-center w-36 h-36 shadow-md border-2 border-secondaryLightest rounded-xl my-4'><img className="p-2 " src={item.image} alt="Integration"/></div>
                <h1 className='text-black font-bold text-lg text-center mb-0 md:mb-2'>{item.name}</h1>
                <h3 className='text-secondary text-sm md:text-md text-center'>{item.type}</h3>
            </div>
       )
    })
    return (
        <section id="featuredIntegration" className='w-full flex flex-col justify-center items-center mt-12'>
            <h1 className='text-black text-center w-full md:px-0 font-bold text-2xl'>Designed to integrate with the software you love.</h1>
            <p className='text-primaryDarker text-md w-3/4 md:w-1/2 text-center my-6'>We don’t make you work around our technology. Our flexible solutions are designed to integrate with other software and systems so it works for you—not the other way around.</p>
            <div  className='flex md:flex-row flex-col items-center justify-center md:w-3/4 md:h-[50vh] text-center py-12 md:bg-gray-200 rounded-xl'>
                <img src={gusto} alt="Featured Integration Gusto!" className='md:h-full md:w-auto w-full' /> 
                <div className='flex flex-col justify-center  items-center md:w-1/2 h-full bg-white px-4 md:px-12 mt-12  md:mt-0  '>
                    <h2 className='text-5xl text-center mb-6 '>The #1 rated HR platform for payroll, benefits, and more.</h2>
                    <p>A well-supported team is the key to a successful business.
                        With Gusto’s easy-to-use platform, you can empower your people
                        and push your business forward.
                    </p>
                    <a href='https://gusto.pxf.io/c/4914425/1226287/15154' className='pb-12 md:pb-0 md:border-0 border-b border-b-gray-300 w-3/4'><button className='px-8 py-4 bg-gustoOrange text-white mt-4 rounded-lg'>LEARN MORE</button></a>
                </div>
            </div>
            <div className='flex md:flex-row flex-col flex-wrap justify-center w-full md:w-3/4 items-center '>
                {newData}
            </div>
        </section>
    )

}

export default Integrations
