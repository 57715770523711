import React from 'react'
import teamwork from '../assets/teamwork.png'
import reserve from '../assets/reserve.png'

export const ProductFeatures = () => {
  return (
    <container className='flex flex-col items-center justify-center w-full mt-6 gap-12'>
        
        <div className='flex md:flex-row flex-col justify-center items-center md:items-start gap-6 md:w-3/4 my-6 '>
            <div className='flex flex-col items-center md:items-start mx-6 mt-12 md:gap-6 w-full'>
                <h2 className='md:text-5xl text-2xl font-bold '>Technology that</h2>
                <h2 className='md:text-6xl text-2xl font-bold md:self-end'>just works!</h2>
            </div>
            <video className='md:w-7/12 rounded-xl' autoPlay muted loop>
                <source src="https://www.spoton.com/videos/restaurant-hardware-hero.webm" type="video/webm" ></source>
            </video>
        </div>
        <div className='flex md:flex-row flex-col justify-center items-center md:items-start gap-6 w-3/4 md:my-12 '>
            <img className="md:w-1/2" src={teamwork} alt="Teamwork Dashboard" />
            <div className='flex flex-col mx-6 md:gap-6 w-full md:mt-12'>
                <h2 className='md:text-5xl text-2xl font-bold '>Make every shift count</h2>
                <h2 className='md:text-5xl text-2xl font-bold self-end'>with SpotOn<span className='text-primary'> Teamwork</span></h2>
                <p className='my-4'>Do your schedule in minutes, control labor costs, and let your team seamlessly manage their shifts with SpotOn Teamwork.</p>
                <a href="https://www.spoton.com/solutions/labor-and-scheduling/" className='self-start mr-12 mt-2'><button className='px-12 py-4 bg-primaryDarker text-white rounded-full'>LEARN MORE</button></a>
            </div>
            
        </div>
        <div className='flex md:flex-row flex-col justify-center items-center md:items-start w-full gap-6 md:w-3/4 md:my-6 '>
            <div className='flex flex-col mx-6 gap-2 md:w-1/3 mt-12'>
                <h2 className='md:text-4xl text-2xl font-bold '>Powering the perfect</h2>
                <h2 className='md:text-4xl text-2xl font-bold md:self-end'><span className='text-primary'> guest experience</span></h2>
                <p className='mt-4'>SpotOn Reserve brings together your reservations, waitlist, and guest information in one place so your staff can make a great impression every time.</p>
            </div>
            <img className="md:w-1/3 w-3/4" src={reserve} alt="Teamwork Dashboard" />
            <div className='flex flex-col md:w-1/3 px-2'>
                <div className='flex flex-col w-full px-4 gap-2 mb-4'>
                    <h2 className='font-bold text-xl'>Online Booking</h2>
                    <p>Get more reservations by allowing guests to book directly from your websites, social media, and Google search results.</p>
                </div>
                <div className='flex flex-col w-full px-4 gap-2 mb-4'>
                    <h2 className='font-bold text-xl'>Automated Reminders</h2>
                    <p>Reduce no-shows and last minute-cancellations with automatic reservation confirmations and 2-way texting capabilities.</p>
                </div>
                <div className='flex flex-col w-full px-4 gap-2 mb-4'>
                    <h2 className='font-bold text-xl'>Host App</h2>
                    <p>See reservations alongside your waitlist and keep tables turning with automatic table suggestions, customizable turn times, and other features.</p>
                </div>
            </div>
            
        </div>
    </container>
  )
}
