const restaurants = [
    {
        "id": 1,
        "image": require("../assets/restaurants/mandez.png"),
        "name": "Mandez's",
        
    },
    {
        "id": 2,
        "image": require("../assets/restaurants/beefmaster.png"),
        "name": "Beefmaster",
        
    },
    {
        "id": 3,
        "image": require("../assets/restaurants/pablo.png"),
        "name": "Senor Pablo",
        
    },
    {
        "id": 4,
        "image": require("../assets/restaurants/cestBon.png"),
        "name": "C'est Bon Crawfish",
        
    },
    {
        "id": 5,
        "image": require("../assets/restaurants/tacosisters.jpg"),
        "name": "Taco Sisters",
        
    },
    {
        "id": 6,
        "image": require("../assets/restaurants/yardgoat.png"),
        "name": "The Yard Goat",
      
    }
]

export default restaurants