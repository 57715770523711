
import '../App.css';
import { useState } from 'react'
import Hero from '../components/Hero';
import Header from '../components/Header'
// import FeatureRight from '../components/FeatureRight'
// import FeatureLeft from '../components/FeatureLeft'
import Serve from '../components/Serve'
import Footer from '../components/Footer'
import AboutUs from '../components/AboutUs'
import DemoModal from '../components/DemoModal'
import Contact from '../components/Contact'
import Integrations from '../components/Integrations'
// import featureInfo from '../components/features'
import BottomNav from '../components/BottomNav'
import { ProductFeatures } from '../components/ProductFeatures';


function App() {
  const [demoForm, setDemoForm] = useState(false)
  return (
    
    <div>
      <div id="home" className='bg-background font-montserrat pb-8 w-full '>
        {!demoForm && <Header showForm={setDemoForm} />}
        {demoForm && <DemoModal closeForm={setDemoForm}/>}
        <Hero />
        <section className='h-20' id="about"></section>
        <AboutUs />
        <Serve />
        <div id="features"></div>
        <div className='flex items-center justify-center w-full py-8 mt-16' ><h1 className='font-bold text-3xl lg:text-6xl text-primaryDarker text-center'>Why Choose Acadian & SpotOn?</h1></div>
        <div className='w-full flex items-center justify-center '>
        <h2 className='text-center w-3/4 text-black text-xl mt-6 -mb-2'>We’re here to help people who want to run their business on their own terms. Because if you’ve got the courage to strike out on your own, you ought to have the freedom to do things the way you want. We believe great tech and support from real people can make the difference between success and failure. And we actually give a damn about helping you succeed. </h2>
        </div>
        <ProductFeatures />
        {/* <FeatureRight id={featureInfo[0].id} title={featureInfo[0].title} explanation={featureInfo[0].explanation} features={featureInfo[0].features} pic={featureInfo[0].pic}/>
        <FeatureLeft id={featureInfo[1].id} title={featureInfo[1].title} explanation={featureInfo[1].explanation} features={featureInfo[1].features} pic={featureInfo[1].pic}/>
        <FeatureRight id={featureInfo[2].id} title={featureInfo[2].title} explanation={featureInfo[2].explanation} features={featureInfo[2].features} pic={featureInfo[2].pic}/>
        <FeatureLeft id={featureInfo[3].id} title={featureInfo[3].title} explanation={featureInfo[3].explanation} features={featureInfo[3].features} pic={featureInfo[3].pic}/>
        <FeatureRight id={featureInfo[4].id} title={featureInfo[4].title} explanation={featureInfo[4].explanation} features={featureInfo[4].features} pic={featureInfo[4].pic}/>
        <h1 className='text-center text-black text-3xl mt-12 mb-8 font-bold'>SpotOn Online Ordering</h1>
        <FeatureLeft id={featureInfo[5].id} title={featureInfo[5].title} explanation={featureInfo[5].explanation} features={featureInfo[5].features} pic={featureInfo[5].pic}/>
        <FeatureRight id={featureInfo[6].id} title={featureInfo[6].title} explanation={featureInfo[6].explanation} features={featureInfo[6].features} pic={featureInfo[6].pic}/>
        <FeatureLeft id={featureInfo[7].id} title={featureInfo[7].title} explanation={featureInfo[7].explanation} features={featureInfo[7].features} pic={featureInfo[7].pic}/>
        <FeatureRight id={featureInfo[8].id} title={featureInfo[8].title} explanation={featureInfo[8].explanation} features={featureInfo[8].features} pic={featureInfo[8].pic}/> */}
        <Integrations />
        <section id="contact" className='mt-6 w-full h-6'></section>
        <Contact />
      </div>
      <Footer />
      <BottomNav />
    </div>
  );
}

export default App;
