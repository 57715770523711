import React, { useEffect, useState } from 'react'
import restaurantList from '../components/restaurantList';
import {MdFastfood} from 'react-icons/md'
import {SiDoordash} from 'react-icons/si'
import {MdRestaurant} from 'react-icons/md'



const Restaurants = () => {
    const [restaurants, setRestaurants] = useState(restaurantList)
    const [filteredRest, setFilteredRest] = useState([])
    const [activeType, setActiveType] = useState('')
    const [myZip, setMyZip] = useState('')
    const [zipValue, setZipValue] = useState('')

    const [radius, setRadius] = useState(10)


    const zipcodes = require('zipcodes')
    const zipRadius = zipcodes.radius(myZip, radius)
    const restZips= restaurantList.map(({zip}) => zip)
    const filteredZips = zipRadius.filter((zip) => restZips.includes(zip))


    useEffect(() => { 
      const newfilter = restaurants.filter((rest) => filteredZips.includes(rest.zip) )
      setFilteredRest(newfilter)
      setRestaurants(newfilter) 
    },[myZip])
    


    useEffect(() => {
      if(activeType === ''){
        setFilteredRest(restaurants)
        return
      } 
      const filtered = restaurants.filter((restaurant) => restaurant.type.includes(activeType))
      setFilteredRest(filtered)      
    }, [activeType])

    useEffect(() => {
      setRestaurants(restaurantList)  
      
    }, [])


    
  return (
    <>
    <div className={myZip.length>1 ? 'hidden' : 'bg-primaryDarker w-full h-[100vh] flex items-center justify-center' }>
      <label  className='text-white'>Please Enter Your Zip Code</label><br />
      <input type="text" value={zipValue} placeholder='Zip Code' className='py-2 px-4 rounded mx-4' name="zip" id="zip" onChange={(e) => setZipValue(e.target.value)}/>
    <button className='w-32 p-1 bg-white text-primaryDarker border-2 rounded-xl'  onClick={() => setMyZip(zipValue)}>Submit</button>
    </div>
    <div id="restType" className={myZip==="" ? 'hidden' : 'bg-primaryDarkest min-h-[100vh] flex flex-col items-center' }>
      <h2 className='text-white pt-4'>Showing Restaurants in a {radius} Mile Radius</h2>    
      <div className= 'pt-6 flex flex-row gap-6 items-center justify-center text-white'>
        <button className={activeType === '' ? 'w-32 p-1 bg-white text-primaryDarker border-2 rounded-xl' : 'w-32 p-1 hover:bg-secOrange2 hover:text-white border-2 rounded-xl'}  onClick={() => setActiveType('')}>All</button>
        <button className={activeType === 'Mexican' ? 'w-32 p-1 bg-white text-primaryDarker border-2 rounded-xl' : 'w-32 p-1 hover:bg-secOrange2 hover:text-white border-2 rounded-xl'} onClick={() => setActiveType('Mexican')}>Mexican</button>
        <button className={activeType === 'Asian' ? 'w-32 p-1 bg-white text-primaryDarker border-2 rounded-xl' : 'w-32 p-1 hover:bg-secOrange2 hover:text-white border-2 rounded-xl'} onClick={() => setActiveType('Asian')}>Asian</button>
        <button className={activeType === 'American' ? 'w-32 p-1 bg-white text-primaryDarker border-2 rounded-xl' : 'w-32 p-1 hover:bg-secOrange2 hover:text-white border-2 rounded-xl'} onClick={() => setActiveType('American')}>American</button>
        
      </div>
      
      <div className="py-8 flex justify-center flex-wrap gap-6 w-full ">
      {filteredRest.map(restaurant => {
        return (
          <div key={restaurant.id} >
          <div className='bg-white w-96 h-96 flex flex-col justify-start items-center rounded-lg overflow-hidden'>
          <div className='bg-white flex items-center justify-center text-secOrange h-6 w-auto p-4 rounded-lg absolute m-2 self-start drop-shadow-[3px_3px_3px_rgba(0,0,0,0.5)]'>{restaurant.style === "fastFood" ? <MdFastfood/> : <MdRestaurant/>}<h2 className='ml-2'>{restaurant.type}</h2></div>
          
          <div className='h-1/2 overflow-hidden mb-6 flex items-center justify-center'>
          <img src={restaurant.image} alt="taco sisters food" className='w-full bg-cover' />
          </div>
          <h1 className='font-bold text-2xl'>{restaurant.name}</h1>
          <h2>{restaurant.street}</h2>
          <h2>{restaurant.city}</h2>
          <a href={restaurant.website} target="_blank" rel="noopener noreferrer"><button className='font-bold text-white bg-secOrange px-4 py-2 rounded-lg mt-4 hover:bg-secOrange2 ease-in-out duration-300'>ORDER NOW</button></a>
          {restaurant.doordash ? <div className='my-2 flex flex-row items-center gap-2 text-secOrange font-montserrat'><div className='flex flex-row items-center text-xs'><span className='text-xl'><SiDoordash/></span><span className='ml-2'>Delivery Available!</span></div><div className='font-bold text-xs'>   ${restaurant.ddcost} FEE / ${restaurant.minOrder} MIN. ORDER</div></div> : null}
          </div>
          
        </div>
        )
      })}
      
      </div>
    </div>
    
    </> 
  )
}

export default Restaurants